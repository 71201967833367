// HomePage.js
import React, { useState } from 'react';
import IdeasList from './IdeasList';
import { useAuth } from './AuthContext';
import EmailCapture from './EmailCapture';

function HomePage() {
  const { session, signInWithGoogle, logout, loading } = useAuth();
  const [email, setEmail] = useState('');
  const [emailSubmitted, setEmailSubmitted] = useState(false)
  const [message, setMessage] = useState('')

  if (loading) return <p>Loading...</p>;

  return (
    <div className="App">
      {/* Header */}
      <header className="flex justify-between items-center p-6 bg-gray-800 text-white">
        <h1 className="text-xl font-bold">📥IdeasInbound.com</h1>
        <div className="flex items-center space-x-4">
          {session ? (
            <div className="dropdown">
              <button className="btn btn-ghost">Account</button>
              <div className="dropdown-content p-2 bg-gray-200 rounded shadow-md">
                <p className="p-2 text-gray-800">{session.user.email}</p>
                <button onClick={logout} className="btn btn-primary btn-sm">
                  Sign Out
                </button>
              </div>
            </div>
          ) : (
            <button onClick={signInWithGoogle} className="btn btn-primary">
              Sign In with Google
            </button>
          )}
        </div>
      </header>

      {/* Conversion-Focused Email Capture Section */}
      <section className="container mx-auto p-4 mb-8 flex items-center justify-center">
        { (!session) &&
         (!emailSubmitted) ?
          (
            <EmailCapture setEmailSubmitted={setEmailSubmitted} message={message} setMessage={setMessage} />
          ) : (
            <p className='flex items-center justify-center mt-12'>{message}</p>
          )
        }
      </section>

      {/* Main Content */}
      <main className="container mx-auto p-4">
        <IdeasList />
      </main>
    </div>
  );
}

export default HomePage;
