import React, { useState, useEffect } from 'react';

export default function Unsubscribe() {
  const [status, setStatus] = useState(null);
  const [email, setEmail] = useState('');
  const [emailFromUrl, setEmailFromUrl] = useState(false);

  useEffect(() => {
    // Extract the email parameter from the URL
    const params = new URLSearchParams(window.location.search);
    const emailParam = params.get('email');
    if (emailParam) {
      setEmail(emailParam);
      setEmailFromUrl(true);
    }
  }, []);

  const handleUnsubscribe = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/email/unsubscribe`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        setStatus('success');
      } else {
        setStatus('error');
      }
    } catch (error) {
      console.error('Error unsubscribing:', error);
      setStatus('error');
    }
  };

  return (
    <div className="unsubscribe flex flex-col items-center justify-center min-h-screen p-4">
      <h1 className="text-3xl font-bold mb-4">Unsubscribe</h1>

      {status === 'success' && (
        <p className="text-green-600 text-lg">
          You have successfully unsubscribed. Thank you!
        </p>
      )}

      {status === 'error' && (
        <p className="text-red-600 text-lg">
          There was an error. Please check your email link or try again later.
        </p>
      )}

      {status === null && (
        <div className="card bg-white shadow-lg p-6 rounded-lg max-w-md w-full">
          {emailFromUrl ? (
            <form onSubmit={handleUnsubscribe} className="flex flex-col items-center gap-4">
              <p className="text-gray-700 text-lg">
                Click below to unsubscribe from emails for <strong>{email}</strong>.
              </p>
              <button type="submit" className="btn btn-error btn-wide">
                Unsubscribe
              </button>
            </form>
          ) : (
            <form onSubmit={handleUnsubscribe} className="flex flex-col gap-4 ">
              <label htmlFor="email" className="text-gray-700 text-lg self-start">
                Enter your email to unsubscribe:
              </label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="input input-bordered w-full bg-white border-gray-500 text-black"
                required
              />
              <button type="submit" className="btn bg-primary border-primary hover:bg-secondary btn-error btn-wide w-full">
                Unsubscribe
              </button>
            </form>
          )}
        </div>
      )}
    </div>
  );
}
