// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AdminPage from './AdminPage';
import HomePage from './HomePage';
import { AuthProvider } from './AuthContext';
import Privacy from './Privacy';
import Terms from './Terms';
import Pricing from './Pricing';
import Unsubscribe from './Unsubscribe';

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/admin" element={<AdminPage />} />
          <Route path="/unsubscribe" element={<Unsubscribe />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
